<template>
  <div class="container">
    <div class="left-container">
      <a-input placeholder="搜索选题" v-model="searchTagName" allowClear></a-input>
      <div class="tree-wrapper">
        <a-spin :spinning="tagLoading">
          <a-tree
            v-if="treeData.length > 0"
            :tree-data="treeData"
            :default-expand-all="true"
            :replaceFields="{ title: 'name' }"
            :show-icon="true"
            :filterTreeNode="filterTreeNode"
            @select="handleTagSelect"
            :default-selected-keys="defaultNode"
          >
            <template #title="data">
              <a-icon
                slot="icon"
                type="folder-open"
                style="margin-right: 5px"
                v-show="data.children && data.children.length > 0"
              />
              <a-dropdown :trigger="['contextmenu']">
                <span>{{ data.tagName }}</span>
                <template #overlay>
                  <a-menu @click="(e) => onContextMenuClick(e, data)">
                    <a-menu-item key="1">重命名</a-menu-item>
                    <a-menu-item key="2" v-if="data.tagLevel < 3">+ 子标签</a-menu-item>
                    <a-menu-item key="3">+ 同级标签</a-menu-item>
                    <a-popconfirm
                      placement="right"
                      ok-text="删除"
                      cancel-text="取消"
                      style="text-align: center; cursor: pointer"
                      @confirm.stop="handleTagDel(data)"
                    >
                      <template slot="title">
                        <p>确认删除标签?</p>
                      </template>
                      <a-menu-item key="4">- 删除标签</a-menu-item>
                    </a-popconfirm>
                    <a-menu-item key="5"
                      ><span class="dott" :style="`background-color: ${data.colorHex}`"></span>修改颜色</a-menu-item
                    >
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-tree>
          <div v-else align="center" style="margin-top: 100px">
            <div style="margin-bottom: 20px">暂无选题标签</div>
            <a-button type="primary" @click="onContextMenuClick({ key: '2' }, { id: 0 })">新增选题标签</a-button>
          </div>
        </a-spin>
      </div>
      <a-button type="link" icon="file-add" @click="jumpToChart">查看自动化生产配置</a-button>
    </div>

    <div class="right-container">
      <!-- 筛选开始 -->
      <a-card>
        <a-row :gutter="[20, 10]">
          <a-col :span="8">
            <brand-select-input
              style="width: 100%"
              placeholder="品牌"
              v-model="extraForm.principalIdListData"
              show-search
              mode="multiple"
              :maxTagCount="1"
              option-filter-prop="children"
              allowClear
              @blur="getDataList(true)"
              @deselect="getDataList(true)"
            ></brand-select-input>
          </a-col>
          <a-col :span="8">
            <car-series-select-input
              style="width: 100%"
              :principalIds="principalIds"
              v-model="extraForm.carSeriesIdListData"
              placeholder="车系"
              show-search
              mode="multiple"
              :filter-option="false"
              :maxTagCount="1"
              allowClear
              @blur="getDataList(true)"
              @deselect="getDataList(true)"
            ></car-series-select-input>
          </a-col>
          <!-- <a-col :span="8" v-show="form.topicSelectionType !== 'COPY_WRITING_TEMPLATE'">
            <a-select
              style="width: 100%"
              placeholder="来源"
              mode="multiple"
              show-search
              option-filter-prop="children"
              :maxTagCount="1"
              allowClear
              v-model="form.createSourceList"
              @blur="getDataList(true)"
              @deselect="getDataList(true)"
            >
              <a-select-option v-for="item in createSourceList" :key="item.value">{{ item.label }}</a-select-option>
            </a-select>
          </a-col> -->
          <a-col :span="8">
            <a-select
              style="width: 100%"
              v-model="form.creator"
              placeholder="添加人"
              show-search
              option-filter-prop="children"
              @change="getDataList(true)"
              allowClear
            >
              <template v-if="form.topicSelectionType !== 'COPY_WRITING_TEMPLATE'">
                <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
                  item.name
                }}</a-select-option>
              </template>
              <template v-if="form.topicSelectionType == 'COPY_WRITING_TEMPLATE'">
                <a-select-option v-for="item in templateCreatorList" :key="item.username" :value="item.username">{{
                  item.name
                }}</a-select-option>
              </template>
            </a-select>
          </a-col>
          <a-col :span="12">
            <a-range-picker
              v-model="extraForm.filterTime"
              :placeholder="['开始时间', '结束时间']"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              style="width: 100%"
              @change="getDataList(true)"
            />
          </a-col>
          <a-col :span="6">
            <a-input-search
              :placeholder="
                form.topicSelectionType == 'COPY_WRITING_TEMPLATE'
                  ? '文案标题/正文'
                  : `${listTypeMap[form.topicSelectionType]}名称/ID`
              "
              v-model="form.search"
              @search="getDataList(true)"
              allowClear
            ></a-input-search>
          </a-col>
        </a-row>
      </a-card>
      <!-- 筛选结束 -->

      <a-card class="list-container">
        <div class="position:relative">
          <a-tabs v-model="form.topicSelectionType" @change="handleTabChange" size="large">
            <a-tab-pane
              v-for="val in Object.keys(listTypeMap)"
              :key="val"
              :tab="`${listTypeMap[val]}（${typeNumberList[val]}）`"
            ></a-tab-pane>
            <!-- <a-tab-pane key="OPERATE" tab="运营爆文"> </a-tab-pane>
            <a-tab-pane key="COPY_WRITING_TEMPLATE" tab="文案模板"> </a-tab-pane>
            <a-tab-pane key="PLATFORM" tab="平台爆文"> </a-tab-pane> -->
          </a-tabs>
          <div class="operation-wrapper">
            <!-- <a-button
              v-show="form.topicSelectionType === 'OPERATE'"
              type="primary"
              icon="file-add"
              @click="jumpToNote"
              >去笔记分析页添加</a-button
            > -->
            <a-button type="primary" @click="openUploadNoteModal">添加</a-button>
            <!-- <a-button
              v-show="form.topicSelectionType === 'COPY_WRITING_TEMPLATE'"
              type="primary"
              @click="openCopywritingTemplateModal"
              >新建模板</a-button
            >  -->
          </div>
        </div>
        <!-- <div class="count" v-show="form.topicSelectionType !== 'COPY_WRITING_TEMPLATE'">
          内容数 {{ pagination.total }}
          <span style="margin-left: 10px">改写次数 {{ pagination.totalCitationCount || 0 }}</span>
        </div> -->
        <div class="alert-box-content" v-if="selectedRowKeys.length > 0">
          <a-icon type="info-circle" class="info" />
          <a-space>
            <div>
              已选择
              <span class="light">{{ selectedRowKeys.length }}</span>
              项
            </div>
            <a @click="clearSelection">清空</a>
            <a-popconfirm
              :title="`确定删除这些${listTypeMap[form.topicSelectionType]}吗?`"
              placement="right"
              ok-text="删除"
              cancel-text="取消"
              @confirm.stop="handleBatchDel"
            >
              <a-button style="margin-left: 20px"> 批量删除 </a-button>
            </a-popconfirm>
            <a-button @click="handleBatchUpdateTopic"> 批量修改选题 </a-button>
            <a-button @click="handleUpdateBrand"> 批量修改品牌车系 </a-button>
          </a-space>
        </div>
        <div style="width: 100%; overflow: auto">
          <!-- 爆文列表 -->
          <a-table
            v-if="form.topicSelectionType !== 'COPY_WRITING_TEMPLATE'"
            key="article"
            style="margin-top: 10px; width: 100%"
            :scroll="{ x: '100%' }"
            :loading="tableLoading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="(record) => record.id"
            :row-selection="rowSelection"
            :pagination="false"
            @change="handleTableChange"
          >
            <div class="info-column" slot="info" slot-scope="text, record">
              <div class="info-left" v-show="record.previewImageUrl" @click="preview(record)">
                <base-img
                  :src="record.previewImageUrl"
                  alt="图片"
                  referrerpolicy="no-referrer"
                  style="cursor: pointer"
                />
              </div>
              <div class="info-right">
                <a-tooltip
                  :title="record.noteTitle"
                  v-if="form.topicSelectionType === 'PLATFORM' || form.topicSelectionType === 'OPERATE'"
                >
                  <div class="title-text" @click="preview(record)">
                    {{ record.noteTitle || '-' }}
                  </div>
                </a-tooltip>
                <a-tooltip :title="record.fileTitle" v-if="form.topicSelectionType === 'IMAGE'">
                  <div class="title-text" @click="preview(record)">
                    {{ record.fileTitle || '-' }}
                  </div>
                </a-tooltip>
                <div
                  class="rightId"
                  v-clipboard:copy="record.objectId"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <div class="rightIdL">ID：{{ record.objectId }}</div>
                  <a-icon type="copy" />
                </div>
              </div>
            </div>
            <div class="media-column" slot="mediaAccount" slot-scope="text, record">
              <div class="media-left" v-show="record.avatar">
                <img :src="record.avatar" alt="头像" referrerpolicy="no-referrer" />
              </div>
              <div class="media-right">
                <div
                  style="margin-left: 15px; cursor: pointer; color: #1890ff"
                  class="blue-text"
                  @click="handleJumpXHS(record)"
                >
                  {{ record.nickname }}
                </div>
                <div class="line-flex right-txt">
                  <div class="logo" v-show="record.authorCode">
                    <img src="@/assets/icon/xhs_logo.png" alt="" />
                  </div>
                  <div>{{ record.authorCode || '-' }}</div>
                </div>
              </div>
            </div>
            <div slot="topicSelectionTagList" slot-scope="text, record">
              <a-tooltip>
                <div slot="title" v-if="record.topicSelectionTagList.length > 1">
                  <a-tag
                    style="margin-bottom: 4px; white-space: normal"
                    :color="item.colorHex"
                    v-for="(item, index) in record.topicSelectionTagList"
                    :key="index"
                  >
                    {{ item.tagName }}
                  </a-tag>
                </div>
                <a-tag
                  style="margin-bottom: 4px; white-space: normal"
                  :color="record.topicSelectionTagList[0].colorHex"
                  v-if="record.topicSelectionTagList.length > 0"
                >
                  {{ record.topicSelectionTagList[0].tagName }}
                </a-tag>
                <a-tag color="blue" v-if="record.topicSelectionTagList.length > 1"
                  >+{{ record.topicSelectionTagList.length - 1 }}</a-tag
                >
              </a-tooltip>
            </div>
            <div slot="tagIds" slot-scope="text, record">
              <a-tooltip>
                <div slot="title" v-if="findList(record.tagIds).length > 1">
                  <a-tag
                    style="margin-bottom: 4px; white-space: normal"
                    :color="item.colorHex"
                    v-for="(item, index) in findList(record.tagIds)"
                    :key="index"
                  >
                    {{ item.tagName }}
                  </a-tag>
                </div>
                <a-tag
                  style="margin-bottom: 4px; white-space: normal"
                  :color="findList(record.tagIds)[0].colorHex"
                  v-if="findList(record.tagIds).length > 0"
                >
                  {{ findList(record.tagIds)[0].tagName }}
                </a-tag>
                <a-tag color="blue" v-if="findList(record.tagIds).length > 1"
                  >+{{ findList(record.tagIds).length - 1 }}</a-tag
                >
              </a-tooltip>
            </div>
            <div slot="createSource" slot-scope="text, record">
              {{ createSourceMap[record.createSource] }}
            </div>
            <div slot="operation" slot-scope="text, record">
              <a @click="handleArticleJump(record.shareLink)">查看原文</a>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a @click="handleAiRewrite(record.objectId)">提取文案</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleUpdateTopic(record)">修改选题</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleUpdateBrand(record)">修改品牌车系</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleDeleteTopic(record)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-table>
          <!-- 推送模板列表 -->
          <a-table
            v-if="form.topicSelectionType == 'COPY_WRITING_TEMPLATE'"
            key="template"
            style="margin-top: 10px; width: 100%"
            :scroll="{ x: '100%' }"
            :loading="tableLoading"
            :columns="pushColumns"
            :data-source="dataList"
            :rowKey="(record) => record.id"
            :row-selection="rowSelection"
            :pagination="false"
            @change="handleTempTableChange"
          >
            <div slot="pushNote" slot-scope="text, record">
              <a-tooltip>
                <template slot="title"> {{ record.template }} </template>
                <div>
                  {{
                    record.template && record.template.length > 22
                      ? record.template.slice(0, 22) + '...'
                      : record.template
                  }}
                </div>
              </a-tooltip>
            </div>
            <div slot="tagIds" slot-scope="text, record">
              <a-tooltip>
                <div slot="title" v-if="findList(record.tagIds).length > 1">
                  <a-tag
                    style="margin-bottom: 4px; white-space: normal"
                    :color="item.colorHex"
                    v-for="(item, index) in findList(record.tagIds)"
                    :key="index"
                  >
                    {{ item.tagName }}
                  </a-tag>
                </div>
                <a-tag
                  style="margin-bottom: 4px; white-space: normal"
                  :color="findList(record.tagIds)[0].colorHex"
                  v-if="findList(record.tagIds).length > 0"
                >
                  {{ findList(record.tagIds)[0].tagName }}
                </a-tag>
                <a-tag color="blue" v-if="findList(record.tagIds).length > 1"
                  >+{{ findList(record.tagIds).length - 1 }}</a-tag
                >
              </a-tooltip>
            </div>
            <div slot="personalityType" slot-scope="text, record">
              <div v-if="record.personalityType === 'SALE'">销售</div>
              <div v-else-if="record.personalityType === 'STORE'">门店</div>
              <div v-else>-</div>
            </div>
            <div slot="operation" slot-scope="text, record">
              <a @click="updateCopywritingTemplate(record.id)">编辑</a>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a @click="handleUpdateTopic(record)">修改选题</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleUpdateBrand(record)">修改品牌车系</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a @click="handleDeleteCopywritingTemplate(record)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-table>
        </div>
        <base-pagination
          :currentPage="pagination.current"
          :options="['10', '30', '50']"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
        />
      </a-card>
    </div>

    <UploadNoteModal
      ref="UploadNoteModal"
      :defaultType="form.topicSelectionType"
      @success="
        () => {
          getDataList();
          handleTrack('add_note_success');
        }
      "
    />
    <TagEditionModal ref="TagEditionModal" @success="getTagList" />
    <BrandEditionModal ref="BrandEditionModal" @success="getDataList()" />
    <CopywritingTemplateModal
      ref="CopywritingTemplateModal"
      :rewriteTypeList="rewriteTypeList"
      @success="getDataList()"
    ></CopywritingTemplateModal>
    <BatchPushToTopicModal ref="BatchPushToTopicModal" @success="getDataList()" />

    <!-- 查看/编辑抽屉 -->
    <WrittingDrawer
      ref="writtingDrawer"
      v-if="showDrawer"
      :readOnly="readOnly"
      :drawerEditData="storyData"
      :topicId="topicId"
      :isLocalData="isLocalData"
      @closeDrawer="closeWrittingDrawer"
      @openDrafEdit="readOnly = false"
    />

    <!-- 图片预览 -->
    <preview-modal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import { noteColumns, pushColumns, createSourceList, createSourceMap, imgColumns, listTypeMap } from './data.js';
import UploadNoteModal from './components/UploadNoteModal';
import CopywritingTemplateModal from './components/CopywritingTemplateModal';
import TagEditionModal from './components/TagEditionModal';
import BatchPushToTopicModal from '@/components/xhsAgencyTool/BatchPushToTopicModal.vue';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import BaseImg from '@/components/BaseImg';
import WrittingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import BrandEditionModal from './components/BrandEditionModal';
import { trackRequest } from '@/utils/track';

export default {
  components: {
    UploadNoteModal,
    CopywritingTemplateModal,
    TagEditionModal,
    BatchPushToTopicModal,
    BrandSelectInput,
    CarSeriesSelectInput,
    BaseImg,
    WrittingDrawer,
    PreviewModal,
    BrandEditionModal,
  },
  data() {
    return {
      pushColumns,
      tagLoading: false,
      treeData: [],
      flatTreeData: [],
      noteColumns,
      createSourceList,
      createSourceMap,
      listTypeMap,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
        totalCitationCount: 0,
      },
      searchTagName: '',
      form: {
        topicSelectionType: 'OPERATE',
        carSeriesIdList: [],
        createSourceList: [],
        creator: undefined,
        endTime: undefined,
        // isNoTag: false,
        principalIdList: [],
        search: undefined,
        sortFieldList: [
          {
            fieldName: 'citationCount',
            orderType: '',
          },
          {
            fieldName: 'ctime',
            orderType: 'desc',
          },
        ],
        startTime: undefined,
        topicSelectionTagIdList: [],
      },
      extraForm: {
        principalIdListData: [],
        carSeriesIdListData: [],
        filterTime: undefined,
      },
      tableLoading: false,
      selectedRowKeys: [],
      creatorList: [],
      templateCreatorList: [],
      dataList: [],

      // 查看详情相关变量
      showDrawer: false,
      readOnly: false,
      isLocalData: false,
      topicId: '',
      storyData: {},

      // 图片预览
      preview_visible: false,
      preview_src: '',

      defaultNode: [],

      rewriteTypeList: [],

      typeNumberList: {
        PLATFORM: 0,
        COPY_WRITING_TEMPLATE: 0,
        OPERATE: 0,
      },

      templateSorter: {
        field: 'ctime',
        order: 'descend',
      },
    };
  },
  computed: {
    columns() {
      let columns = [];
      switch (this.form.topicSelectionType) {
        case 'PLATFORM': // 平台爆文
          columns = noteColumns;
          break;
        case 'OPERATE': // 运营爆文
          columns = noteColumns.filter((col) => col.title !== '来源');
          break;
        case 'IMAGE': // 成品库
          columns = imgColumns;
          break;
        // case 'COPY_WRITING_TEMPLATE': // 文案模板
        //   columns = pushColumns;
        //   break;
        default:
          break;
      }
      return columns;
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
    principalIds() {
      return this.extraForm.principalIdListData.length > 0
        ? this.extraForm.principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },
  },
  methods: {
    //编辑文案模板
    updateCopywritingTemplate(id) {
      this.$refs['CopywritingTemplateModal'].openModal(id);
    },
    findList(str) {
      const result = [];
      if (str) {
        //又可能是undifine
        if (str.includes(',')) {
          const arr = str.split(',');
          this.flatTreeData.forEach((item) => {
            arr.forEach((val) => {
              if (item.id === val) {
                result.push(item);
              }
            });
          });
        } else {
          this.flatTreeData.forEach((item) => {
            if (item.id === str) {
              result.push(item);
            }
          });
        }
      }
      return result;
    },
    //数组降维
    flattenTree(tree) {
      const result = [];

      function traverse(node) {
        result.push(node);
        if (node.children.length > 0) {
          for (const child of node.children) {
            traverse(child);
          }
        }
      }
      for (const item of tree) {
        traverse(item);
      }

      return result;
    },
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.tagPath,
        key: data.id,
        colorHex: data.colorHex,
      };
    },
    /**
     * 获取标签列表
     */
    async getTagList() {
      this.tagLoading = true;
      await this.handleRequest(
        'getTopicTagTree',
        (data) => {
          this.treeData = data.map(this.initTagValue);
          this.flatTreeData = this.flattenTree(data);
        },
        0
      );
      this.tagLoading = false;
    },
    /**
     * 删选树节点
     * @param {object} node 节点信息
     * @return {boolean} 是否高亮
     */
    filterTreeNode(node) {
      return this.searchTagName ? node.$options.propsData.dataRef.name.includes(this.searchTagName) : false;
    },
    /**
     * 右击菜单
     * @param {*} data
     * @return {*}
     */
    onContextMenuClick({ key: eventState }, data) {
      this.eventState = eventState;
      this.editTag = { id: '' };
      if (eventState === '1') {
        this.$refs['TagEditionModal'].openModal({
          title: '重命名标签',
          form: {
            id: data.id,
            tagName: data.tagName,
          },
        });
      } else if (eventState === '2') {
        this.handleTrack('add_topic');
        this.$refs['TagEditionModal'].openModal({
          title: '添加子标签',
          form: {
            parentId: data.id,
            tagName: '',
            colorHex: '#adafb3',
          },
        });
      } else if (eventState === '3') {
        this.handleTrack('add_topic');
        this.$refs['TagEditionModal'].openModal({
          title: '添加同级标签',
          form: {
            parentId: data.parentId,
            tagName: '',
            colorHex: '#adafb3',
          },
        });
      } else if (eventState === '5') {
        this.$refs['TagEditionModal'].openModal({
          title: '修改颜色',
          form: {
            id: data.id,
            colorHex: data.colorHex,
          },
        });
      }
    },
    /**
     * 删除标签
     * @param {*} id
     */
    handleTagDel({ id }) {
      this.handleTrack('delete_topic');

      this.handleRequest(
        'deleteTopicTag',
        () => {
          this.$message.success('删除成功');
          this.getTagList();
        },
        id
      );
    },
    /**
     * 选择标签
     * @param {*} val
     */
    handleTagSelect(val) {
      this.form.topicSelectionTagIdList = val;
      this.getDataList(true);
    },
    /**
     * 打开编辑标签弹窗

     */
    openUploadNoteModal() {
      this.$refs['UploadNoteModal'].openModal();
      this.handleTrack('add_note');
    },
    openCopywritingTemplateModal() {
      this.$refs['CopywritingTemplateModal'].openModal();
    },
    /**
     * 获取选题添加人列表
     */
    async getCreatorList() {
      this.handleRequest('getTopicCreatorList', (data) => {
        this.creatorList = data;
      });
      this.handleRequest('getTemplateCreatorList', (data) => {
        this.templateCreatorList = data;
      });
    },
    /**
     * 获取爆文总数
     */
    async getTopicTypeTotal(params = {}) {
      this.handleRequest(
        'getTopicTypeTotal',
        (data) => {
          data.forEach((value) => {
            Object.keys(this.typeNumberList).forEach((keys) => {
              if (keys === value.topicSelectionType) {
                this.typeNumberList[keys] = value.count;
              }
            });
          });
        },
        params
      );
    },
    /**
     * 获取列表
     * @param {boolean} isFirst 是否加载首页
     */
    async getDataList(isFirst) {
      this.selectedRowKeys = [];
      this.dataList = [];
      if (isFirst) this.pagination.current = 1;
      if (this.form.topicSelectionType === 'COPY_WRITING_TEMPLATE') {
        // 文案模板
        this.$nextTick(async () => {
          const params = {
            page: this.pagination.current,
            size: this.pagination.pageSize,
            // topicSelectionList: this.form.topicSelectionTagIdList[0] ? [this.form.topicSelectionTagIdList[0]] : [],
            creator: this.form.creator,
            principalIdList:
              this.extraForm.principalIdListData.length > 0
                ? this.extraForm.principalIdListData.map((item) => {
                    const arr = item.split(',');
                    return arr.length > 0 ? arr[0] : '';
                  })
                : [],
            carSeriesIdList:
              this.extraForm.carSeriesIdListData.length > 0
                ? this.extraForm.carSeriesIdListData.map((item) => {
                    const arr = item.split(',');
                    return arr.length > 0 ? arr[0] : '';
                  })
                : [],
            startTime:
              this.extraForm.filterTime && this.extraForm.filterTime[0]
                ? this.extraForm.filterTime[0] + ' 00:00:00'
                : undefined,
            endTime:
              this.extraForm.filterTime && this.extraForm.filterTime[1]
                ? this.extraForm.filterTime[1] + ' 23:59:59'
                : undefined,
            search: this.form.search?.trim() || undefined,
            topicSelectionTagIdList: this.form.topicSelectionTagIdList,
          };
          if (this.templateSorter.field == 'ctime') {
            // isCtimeAsc 创建时间正序排序
            params.isCtimeAsc = this.templateSorter.order == 'ascend' ? true : false;
          }
          if (this.templateSorter.field == 'mtime') {
            // isMtimeAsc 修改时间正序排序
            params.isMtimeAsc = this.templateSorter.order == 'ascend' ? true : false;
          }
          this.getTopicTypeTotal({
            carSeriesIdList: params.carSeriesIdList,
            creator: params.creator,
            endTime: params.endTime,
            principalIdList: params.principalIdList,
            search: params.search,
            startTime: params.startTime,
            topicSelectionTagIdList: this.form.topicSelectionTagIdList,
          });
          this.tableLoading = true;
          await this.handleRequest(
            'getCopywritingTemplateList',
            (data) => {
              this.dataList = data.list.map((item) => ({
                ...item,
                principalName: item.principalName || '-',
                carSeriesName: item.carSeriesName || '-',
              }));
              this.pagination.total = data.total;
            },
            params
          ).finally(() => (this.tableLoading = false));
        });
      } else {
        // 运营爆文/平台爆文
        let createSourceList = [];
        switch (this.form.topicSelectionType) {
          case 'OPERATE':
            createSourceList = ['NOTE_ANALYSE'];
            break;
          case 'PLATFORM':
            createSourceList = ['MANUAL', 'KNOWLEDGE'];
            break;
          default:
            createSourceList = [];
            break;
        }

        this.$nextTick(async () => {
          const params = {
            ...this.form,
            // topicSelectionType: 'PLATFORM',
            createSourceList,
            principalIdList:
              this.extraForm.principalIdListData.length > 0
                ? this.extraForm.principalIdListData.map((item) => {
                    const arr = item.split(',');
                    return arr.length > 0 ? arr[0] : '';
                  })
                : [],
            carSeriesIdList:
              this.extraForm.carSeriesIdListData.length > 0
                ? this.extraForm.carSeriesIdListData.map((item) => {
                    const arr = item.split(',');
                    return arr.length > 0 ? arr[0] : '';
                  })
                : [],
            startTime:
              this.extraForm.filterTime && this.extraForm.filterTime[0]
                ? this.extraForm.filterTime[0] + ' 00:00:00'
                : undefined,
            endTime:
              this.extraForm.filterTime && this.extraForm.filterTime[1]
                ? this.extraForm.filterTime[1] + ' 23:59:59'
                : undefined,
            page: this.pagination.current,
            size: this.pagination.pageSize,
          };
          this.getTopicTypeTotal({
            carSeriesIdList: params.carSeriesIdList,
            creator: params.creator,
            endTime: params.endTime,
            principalIdList: params.principalIdList,
            search: params.search,
            startTime: params.startTime,
            topicSelectionTagIdList: params.topicSelectionTagIdList,
          });
          this.tableLoading = true;
          await this.handleRequest(
            'getTopicList',
            (data) => {
              this.dataList = data.list.map((item) => {
                if (item.previewImageUrl) {
                  if (
                    item.previewImageUrl.indexOf('xhscdn') === -1 &&
                    item.previewImageUrl.indexOf('snapshot') == -1 &&
                    (item.previewImageUrl.indexOf('content') > -1 || item.previewImageUrl.indexOf('afanti') > -1)
                  ) {
                    if (item.previewImageUrl.indexOf('x-oss-process') > -1) {
                      item.previewImageUrl = item.previewImageUrl += `/resize,l_120,m_lfit`;
                    } else {
                      item.previewImageUrl = item.previewImageUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
                    }
                  }
                }
                return {
                  ...item,
                  principalName: item.principalName || '-',
                  carSeriesName: item.carSeriesName || '-',
                  creator: item.creator || '-',
                };
              });
              this.pagination.total = data.total;
              this.pagination.totalCitationCount = data.totalCitationCount;
            },
            params
          ).finally(() => (this.tableLoading = false));
        });
      }
    },
    /**
     * 翻页
     * @param {*} current
     * @param {*} pageSize
     */
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    /**
     * 列表选择
     * @param {*} selectedRowKeys
     */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 清空选择
     */
    clearSelection() {
      this.selectedRowKeys = [];
    },
    /**
     * 排序
     * @param {*} _
     * @param {*} __
     * @param {*} sorter
     * @return {*}
     */
    handleTableChange(_, __, sorter) {
      const orderMap = {
        ascend: 'asc',
        descend: 'desc',
      };

      if (sorter.field === 'citationCount') {
        this.form.sortFieldList[0].orderType = sorter.order ? orderMap[sorter.order] : '';
      } else if (sorter.field === 'ctime') {
        this.form.sortFieldList[1].orderType = sorter.order ? orderMap[sorter.order] : '';
      }
      this.getDataList();
    },
    handleTempTableChange(_, __, sorter) {
      this.templateSorter = {
        field: sorter.field,
        order: sorter.order,
      };
      this.getDataList(true);
    },
    /**
     * 打开笔记详情
     * @param {*} record
     */
    handleOpenNotePreview(record) {
      this.topicId = record.id;
      this.readOnly = true;
      this.showDrawer = true;
    },
    closeWrittingDrawer() {
      this.readOnly = false;
      this.isLocalData = false;
      this.noteId = undefined;
      this.topicId = undefined;
      this.showDrawer = false;
    },
    /**
     * 查看图片
     * @param {*} url 图片地址
     */
    openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.width = '480px';
      this.preview_visible = true;
      this.preview_src = url;
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    /**
     * 预览
     */
    preview(record) {
      this.handleTrack('view_note_detail', {
        note_id: record.objectId,
      });

      if (this.form.topicSelectionType === 'PLATFORM' || this.form.topicSelectionType === 'OPERATE') {
        this.handleOpenNotePreview(record);
      } else {
        this.openBigImg(record.previewImageUrl);
      }
    },
    /**
     * 写笔记
     */
    publishNote(imageId) {
      const selectList = this.dataList.filter((item) => (imageId ? [imageId] : this.selectedRowKeys).includes(item.id));
      let principalId = selectList[0].principalId;
      let principalName = selectList[0].principalName;
      for (let index = 1; index < selectList.length; index++) {
        const nextPrincipalId = selectList[index].principalId;
        if (principalId !== nextPrincipalId) {
          principalId = '';
          principalName = '';
          break;
        }
      }
      let seriesId = selectList[0].carSeriesId;
      let seriesName = selectList[0].carSeriesName;
      for (let index = 1; index < selectList.length; index++) {
        const nextSeriesId = selectList[index].carSeriesId;
        if (seriesId !== nextSeriesId) {
          seriesId = '';
          seriesName = '';
          break;
        }
      }
      const storyData = {
        picList: selectList.map((item) => ({
          id: Math.random(),
          showHover: false,
          fileUrl: item.previewImageUrl,
          fileId: item.objectId,
        })),
        postParams: {
          principalId,
          principalName,
          seriesId,
          seriesName,
        },
      };
      this.readOnly = false;
      this.isLocalData = true;
      this.storyData = storyData;
      this.showDrawer = true;
    },
    //删除文案模板
    handleDeleteCopywritingTemplate(record) {
      this.$confirm({
        title: '提示',
        content: `确定删除该${this.listTypeMap[this.form.topicSelectionType]}吗?`,
        onOk: () => {
          const params = {
            ids: record.id,
          };
          this.handleRequest(
            'deleteCopywritingTemplate',
            () => {
              this.$message.success('删除成功');
              this.getDataList();
            },
            params
          );
        },
      });
    },
    /**
     * 删除选题
     * @param {*} record
     */
    handleDeleteTopic(record) {
      this.handleTrack('delete_note');

      this.$confirm({
        title: '提示',
        content: `确定删除该${this.listTypeMap[this.form.topicSelectionType]}吗?`,
        onOk: () => {
          const params = [];
          if (record) {
            params.push(record.id);
          } else {
            params.push(...this.selectedRowKeys);
          }
          this.handleRequest(
            'deleteTopicByIdList',
            () => {
              this.$message.success('删除成功');
              this.getDataList();
            },
            params
          );
        },
      });
    },
    /**
     * 修改选题
     * @param {*} record
     */
    handleUpdateTopic(record) {
      this.handleTrack('edit_note_topic');

      if (this.form.topicSelectionType === 'COPY_WRITING_TEMPLATE') {
        if (record) {
          const params = {
            id: record.id,
            personalityType: record.personalityType,
            rewriteType: record.rewriteType,
            template: record.template,
            principalId: record.principalId,
            principalName: record.principalName,
            carSeriesName: record.carSeriesName,
            carSeriesId: record.carSeriesId,
          };
          const selectList = [];
          selectList.push(...(this.findList(record.tagIds) || []).map((item) => item.id));
          this.$refs['BatchPushToTopicModal'].openModal({ type: '文案模板', dataList: params, selectList });
        }
        return;
      }
      if (this.form.topicSelectionType !== 'COPY_WRITING_TEMPLATE') {
        const params = [];
        const selectList = [];
        if (record) {
          params.push(record.id);
          selectList.push(...(record.topicSelectionTagList || []).map((item) => item.id));
        } else {
          params.push(...this.selectedRowKeys);
        }
        this.$refs['BatchPushToTopicModal'].openModal({ type: '修改', dataList: params, selectList });
      }
    },
    async getRewriteTypeList() {
      const { code, data, message } = await api.getToolTypeList();
      if (code === 200) {
        this.rewriteTypeList = data;
      } else {
        this.$message.error(message);
      }
    },
    /**
     * 修改品牌车系
     */
    handleUpdateBrand(record) {
      this.handleTrack('edit_note_brand_car');

      const params = {
        idList: [],
        form: {
          principalData: record.principalId ? `${record.principalId},${record.principalName}` : undefined,
          carSeriesData: record.carSeriesId ? `${record.carSeriesId},${record.carSeriesName}` : undefined,
        },
        topicSelectionType: this.form.topicSelectionType,
      };
      if (record && record.id) {
        params.idList = [record.id];
      } else {
        // 批量修改
        params.idList = this.selectedRowKeys;
      }
      this.$refs.BrandEditionModal.openModal(params);
    },
    /**
     * 查看原文
     */
    handleArticleJump(shareLink) {
      if (!shareLink) return this.$message.error(`笔记链接有误`);
      window.open(shareLink, '_blank');
    },
    /**
     * Ai改写
     */
    handleAiRewrite(noteId) {
      this.handleTrack('extract_text');

      if (!noteId) return this.$message.error(`笔记ID有误`);
      let routeData = this.$router.resolve({
        name: 'articleDetail',
        query: { noteId },
      });
      window.open(routeData.href, '_blank');
      // const routeUrl = this.$router.resolve({
      //   name: 'makeAiStory',
      //   query: {
      //     noteId,
      //   },
      // });
      // window.open(routeUrl.href, '_blank');
    },
    /**
     * 统一请求方法
     * @param {*} APINAME
     * @param {*} callbackFn
     * @param {*} PARAMS
     */
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      try {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    /**
     * 复制成功
     */
    onCopySuccess() {
      this.$message.success('已复制ID');
    },
    /**
     * 复制失败
     */
    onCopyError() {
      this.$message.error('复制失败');
    },
    // 跳转笔记分析页
    jumpToNote() {
      const link = this.$router.resolve({
        name: 'xhsNoteAnalysis',
      });
      window.open(link.href, '_blank');
    },
    // 跳转文档
    jumpToChart() {
      const link =
        'https://doc.weixin.qq.com/sheet/e3_ASQAgQY3AK0ekbbvMu6RUe1jMb1qI?scode=ACEAcQe6AA4hqzWb5cAXgA4AbhALY&tab=BB08J2';
      window.open(link, '_blank');
    },
    handleTabChange() {
      this.getDataList(true);
      this.handleTrack('toggle_note_type');
    },
    // 批量删除
    handleBatchDel() {
      this.handleRequest(
        'handleTopicBatchDel',
        () => {
          this.$message.success('操作成功');
          this.getDataList(true);
        },
        {
          idList: this.selectedRowKeys,
          topicSelectionType: this.form.topicSelectionType,
        }
      );
    },
    // 批量修改选题
    handleBatchUpdateTopic() {
      this.$refs['BatchPushToTopicModal'].openModal({
        type: '批量修改',
        dataList: this.selectedRowKeys,
        topicSelectionType: this.form.topicSelectionType,
      });
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
  mounted() {
    if (this.$route.query.topicId) {
      this.form.topicSelectionTagIdList = [this.$route.query.topicId];
      this.defaultNode = [this.$route.query.topicId];
    }
    this.getRewriteTypeList();
    this.getTagList();
    this.getCreatorList();
    this.getDataList(true);
  },
};
</script>

<style scoped lang="less">
.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.light {
  padding: 0 5px;
  color: #1890ff;
  cursor: pointer;
}

.container {
  display: flex;
  background-color: #f0f2f5;
  min-height: calc(100vh - 120px);
  margin-bottom: -17px;
  overflow: hidden;
  align-self: stretch;

  .left-container {
    width: 250px;
    flex-shrink: 0;
    background-color: #fff;
    padding: 16px 16px 16px 0;
    display: flex;
    flex-direction: column;
    .tree-wrapper {
      width: 100%;
      flex: 1;
      overflow: auto;
    }
  }

  .right-container {
    flex: 1;
    margin-left: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .list-container {
      flex: 1;
      margin-top: 10px;
      ::v-deep .ant-card-body {
        padding-top: 10px;
      }

      .operation-wrapper {
        position: absolute;
        height: 56px;
        right: 24px;
        top: 10px;
        bottom: 0;
        display: flex;
        align-items: center;
      }
      .count {
        margin-bottom: 10px;
      }

      .alert-box-content {
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
        position: relative;
        padding: 8px 15px 8px 37px;
        word-wrap: break-word;
        border-radius: 4px;
        .info {
          position: absolute;
          top: 50%;
          left: 13px;
          transform: translateY(-50%);
          color: #1890ff;
        }
      }

      .info-column {
        display: flex;
        margin-top: 10px;
        text-align: left;

        .info-left {
          width: 60px;
          height: 60px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .info-right {
          flex: 1;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
            cursor: pointer;
          }

          .rightId {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #ae7983;
            cursor: pointer;

            .rightIdL {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 10px;
            }
          }
        }
      }

      .media-column {
        display: flex;
        margin-top: 10px;
        text-align: left;

        .media-left {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .media-right {
          flex: 1;

          .right-txt {
            justify-content: start;

            .logo {
              width: 20px;
              height: 20px;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.dott {
  display: inline-block;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.color-block {
  box-sizing: content-box;
  display: inline-block;
  padding: 5px;
  width: 100px;
  height: 30px;
  border: 1px solid rgba(128, 128, 128, 0.288);
  cursor: pointer;
}
</style>
