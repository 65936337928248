export const noteColumns = [
  {
    align: 'center',
    title: '文案',
    width: 340,
    scopedSlots: { customRender: 'info' },
  },
  {
    align: 'center',
    title: '媒体号',
    width: 220,
    scopedSlots: { customRender: 'mediaAccount' },
  },
  {
    align: 'center',
    title: '品牌',
    dataIndex: 'principalName',
    width: 100,
  },
  {
    align: 'center',
    title: '车系',
    dataIndex: 'carSeriesName',
    width: 150,
  },
  {
    align: 'center',
    title: '选题',
    scopedSlots: { customRender: 'topicSelectionTagList' },
    width: 150,
  },
  // {
  //   align: 'center',
  //   title: '改写次数',
  //   dataIndex: 'citationCount',
  //   width: 120,
  //   sorter: () => {},
  // },
  // {
  //   align: 'center',
  //   title: '来源',
  //   scopedSlots: { customRender: 'createSource' },
  //   width: 100,
  // },
  {
    align: 'center',
    title: '添加时间',
    dataIndex: 'ctime',
    width: 150,
    sorter: () => {},
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    align: 'center',
    title: '添加人',
    dataIndex: 'creator',
    width: 100,
  },
  {
    align: 'center',
    title: '操作',
    width: 155,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];

export const imgColumns = [
  {
    align: 'center',
    title: '图片',
    width: 320,
    scopedSlots: { customRender: 'info' },
  },
  {
    align: 'center',
    title: '品牌',
    dataIndex: 'principalName',
    width: 100,
  },
  {
    align: 'center',
    title: '车系',
    dataIndex: 'carSeriesName',
    width: 150,
  },
  {
    align: 'center',
    title: '选题',
    scopedSlots: { customRender: 'topicSelectionTagList' },
    width: 160,
  },
  // {
  //   align: 'center',
  //   title: '改写次数',
  //   dataIndex: 'citationCount',
  //   width: 150,
  //   sorter: () => {},
  // },
  {
    align: 'center',
    title: '来源',
    scopedSlots: { customRender: 'createSource' },
    width: 150,
  },
  {
    align: 'center',
    title: '添加人',
    dataIndex: 'creator',
    width: 150,
  },
  {
    align: 'center',
    title: '添加时间',
    dataIndex: 'ctime',
    width: 150,
    sorter: () => {},
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    align: 'center',
    title: '操作',
    width: 140,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];
export const pushColumns = [
  {
    align: 'center',
    title: '文案',
    width: 200,
    // dataIndex: 'template',
    scopedSlots: { customRender: 'pushNote' },
  },
  {
    align: 'center',
    title: '品牌',
    width: 100,
    dataIndex: 'principalName',
  },
  {
    align: 'center',
    title: '车系',
    width: 150,
    dataIndex: 'carSeriesName',
  },
  {
    align: 'center',
    title: '选题',
    width: 160,
    scopedSlots: { customRender: 'tagIds' },
  },
  {
    align: 'center',
    title: '账号人设',
    width: 150,
    scopedSlots: { customRender: 'personalityType' },
  },
  {
    align: 'center',
    title: '添加时间',
    dataIndex: 'ctime',
    width: 150,
    sorter: () => {},
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    align: 'center',
    title: '更新时间',
    dataIndex: 'mtime',
    width: 150,
    sorter: () => {},
    sortDirections: ['descend', 'ascend', 'descend'],
  },
  {
    align: 'center',
    title: '操作人',
    width: 140,
    customRender: (text, record, index) => {
      return record.actor || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    width: 150,
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
  },
];

export const createSourceList = [
  {
    label: '图片库',
    value: 'PICTURE',
  },
  {
    label: '笔记分析',
    value: 'NOTE_ANALYSE',
  },
  {
    label: '知识库',
    value: 'KNOWLEDGE',
  },
  {
    label: '人工上传',
    value: 'MANUAL',
  },
];
export const createSourceMap = createSourceList.reduce((prev, next) => {
  prev[next.value] = next.label;
  return prev;
}, {});

export const listTypeMap = {
  OPERATE: '运营爆文',
  COPY_WRITING_TEMPLATE: '文案模板',
  PLATFORM: '平台爆文',
};
