<template>
  <img :src="showSrc" referrerpolicy="no-referrer" />
</template>

<script>
const defaultImg = require('@/assets/images/img-default.png');
export default {
  props: {
    src: {
      type: String,
      default: defaultImg,
    },
  },
  data() {
    return {
      showSrc: defaultImg,
    };
  },
  watch: {
    src: {
      handler(newVal) {
        if (newVal) {
          const newImg = new Image();
          newImg.setAttribute('referrerpolicy', 'no-referrer');
          // newImg.setAttribute('crossOrigin', 'anonymous');
          newImg.src = this.src;
          newImg.onerror = () => {
            this.showSrc = defaultImg;
          };
          newImg.onload = () => {
            this.showSrc = newVal;
          };
        }
      },
      immediate: true,
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="less"></style>
