<template>
  <a-modal
    title="修改品牌车系"
    v-model="visible"
    width="380px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
  >
    <brand-select-input
      style="width: 100%"
      placeholder="品牌"
      v-model="form.principalData"
      show-search
      option-filter-prop="children"
      @change="form.carSeriesData = undefined"
      allowClear
    ></brand-select-input>
    <car-series-select-input
      style="width: 100%; margin: 10px 0"
      :principalIds="principalIds"
      v-model="form.carSeriesData"
      placeholder="车系"
      show-search
      :filter-option="false"
      :maxTagCount="2"
      allowClear
    ></car-series-select-input>
    <div style="color: #808080">将覆盖原品牌车系</div>
  </a-modal>
</template>

<script>
import api from '@/api/xhsAgencyApi.js';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';

export default {
  components: { BrandSelectInput, CarSeriesSelectInput },
  data() {
    return {
      visible: false,
      saveLoading: false,
      form: {
        principalData: undefined,
        carSeriesData: undefined,
      },
      idList: [],
      topicSelectionType: undefined,
    };
  },
  computed: {
    principalIds() {
      return this.form.principalData ? [this.form.principalData] : [];
    },
  },
  methods: {
    openModal({ idList, form, topicSelectionType }) {
      Object.assign(this.$data, this.$options.data());
      this.idList = idList;
      this.form = form;
      this.topicSelectionType = topicSelectionType;
      this.visible = true;
    },

    onClose() {
      this.visible = false;
      this.form = {
        principalData: undefined,
        carSeriesData: undefined,
      };
      this.idList = [];
      this.topicSelectionType = undefined;
    },

    async save() {
      const params = {
        idList: this.idList,
        topicSelectionType: this.topicSelectionType,
        principalId: this.form.principalData ? this.form.principalData.split(',')[0] : '',
        principalName: this.form.principalData ? this.form.principalData.split(',')[1] : '',
        carSeriesId: this.form.carSeriesData ? this.form.carSeriesData.split(',')[0] : '',
        carSeriesName: this.form.carSeriesData ? this.form.carSeriesData.split(',')[1] : '',
      };
      this.saveLoading = true;
      const { code, message } = await api.handleTopicBatchUpdateBrand(params).finally(() => {
        this.saveLoading = false;
      });
      if (code === 200) {
        this.$message.success('操作成功');
        this.$emit('success');
        this.onClose();
      } else {
        this.$message.error(message);
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
