<template>
  <a-modal
    :title="title"
    v-model="visible"
    width="380px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
  >
    <div style="display: flex; align-items: center" v-show="title !== '修改颜色'">
      标签名称：
      <a-input v-model.trim="form.tagName" placeholder="标签名称" style="width: 70%" />
    </div>
    <div style="display: flex; align-items: center; margin-top: 10px" v-show="title !== '重命名标签'">
      标签颜色：
      <a-popover v-model="showColor" title="" trigger="click" placement="right">
        <div slot="content">
          <Sketch v-model="form.colorHex" @input="updateColor" />
        </div>
        <span class="color-block" @click="showColor = true">
          <span class="block-preview" :style="`background-color: ${form.colorHex};`">标签内容</span>
        </span>
      </a-popover>
    </div>
  </a-modal>
</template>

<script>
import api from '@/api/xhsAgencyApi.js';
import { Sketch } from 'vue-color';

export default {
  components: { Sketch },
  data() {
    return {
      visible: false,
      saveLoading: false,
      title: '',
      form: {
        parentId: '',
        tagName: '',
        colorHex: '#adafb3',
      },
      showColor: false,
    };
  },
  computed: {
    saveApi() {
      if (this.title == '重命名标签' || this.title == '修改颜色') {
        return api.editTopicTag;
      } else if (this.title === '添加子标签' || this.title === '添加同级标签') {
        return api.addTopicChildTag;
      }
      return '';
    },
  },
  methods: {
    openModal({ title, form }) {
      Object.assign(this.$data, this.$options.data());
      this.title = title;
      this.form = form;
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    updateColor(value) {
      this.form.colorHex = value.hex8;
    },
    save() {
      if (!this.form.tagName && this.title !== '修改颜色') {
        this.$message.warning('标签名称不能为空!');
        return;
      }
      this.saveLoading = true;
      this.saveApi(this.form)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$message.success('操作成功');
            this.$emit('success');
            this.visible = false;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.color-block {
  box-sizing: content-box;
  display: inline-block;
  padding: 5px;
  width: 45%;
  height: 30px;
  color: #fff;
  border: 1px solid rgba(128, 128, 128, 0.288);
  cursor: pointer;

  .block-preview {
    display: inline-block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
  }
}
</style>
