<template>
  <a-modal title="添加文案" :maskClosable="false" v-model="visible" @cancel="onClose" width="700px" centered>
    <a-form-model ref="form" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
      <a-row :gutter="[10, 10]">
        <a-col :span="24">
          <topic-tree-input
            show-search
            style="width: 100%"
            v-model="form.topicSelectionTagIdList"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{ title: 'name' }"
            placeholder="选题"
            allow-clear
            multiple
            tree-default-expand-all
            :maxTagCount="3"
          >
          </topic-tree-input>
        </a-col>
        <a-col :span="12">
          <brand-select-input
            style="width: 100%"
            placeholder="品牌"
            v-model="form.principalData"
            show-search
            option-filter-prop="children"
            allowClear
            @onChange="handleBrandChange"
          ></brand-select-input>
        </a-col>
        <a-col :span="12">
          <car-series-select-input
            style="width: 100%"
            :principalIds="principalIds"
            v-model="form.carSeriesData"
            placeholder="车系"
            show-search
            :filter-option="false"
            :maxTagCount="2"
            allowClear
          ></car-series-select-input>
        </a-col>
      </a-row>
      <a-form-model-item label="类型" prop="type">
        <a-radio-group v-model="form.type" @change="handleRadioChange">
          <a-radio v-for="val in Object.keys(listTypeMap)" :value="val" :key="val">
            {{ listTypeMap[val] }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <div v-if="form.type !== 'COPY_WRITING_TEMPLATE'">
        <div class="tips">
          <div>
            输入小红书笔记ID或笔记链接{{ form.type == 'OPERATE' ? '（须是代运营媒体号所发）' : ''
            }}<a-tooltip>
              <template slot="title">
                <div>笔记ID：从笔记分析、选题库复制</div>
                <div>笔记链接：小红书笔记分享链接</div>
              </template>
              <span style="cursor: pointer"> <a-icon type="question-circle" style="margin-left: 5px" /> </span>
            </a-tooltip>
          </div>
          <a @click="getNoteList">获取笔记</a>
        </div>
        <a-textarea :auto-size="{ minRows: 1, maxRows: 5 }" placeholder="多条笔记用回车或逗号分割" v-model="noteParam">
        </a-textarea>
        <div class="tips">已选：{{ noteList.length }}个</div>
        <a-spin :spinning="noteListLoading" tip="笔记获取中…">
          <div class="note-list">
            <div class="info-column" v-for="(item, index) in noteList" :key="index">
              <div class="info-left">
                <img
                  :src="item.materialArr && item.materialArr.length > 0 ? item.materialArr[0].previewUrl : ''"
                  alt="头像"
                  referrerpolicy="no-referrer"
                />
              </div>
              <div class="info-right">
                <div class="title-text">
                  {{ item.noteTitle }}
                </div>
                <div>ID：{{ item.noteId }}</div>
              </div>
            </div>
            <a-result v-if="getNoteListFailed" status="error" sub-title="笔记获取失败，请重试" />
          </div>
        </a-spin>
      </div>
      <div v-if="form.type === 'COPY_WRITING_TEMPLATE'">
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="form.title" placeholder="请输入标题"></a-input>
        </a-form-model-item>
        <a-form-model-item label="正文" prop="template" required>
          <a-textarea
            :auto-size="{ minRows: 10, maxRows: 15 }"
            placeholder="请输入正文"
            v-model="form.template"
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="账号人设">
          <a-select v-model="form.personalityType" placeholder="人设">
            <a-select-option value="SALE"> 销售 </a-select-option>
            <a-select-option value="STORE"> 门店 </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
    </a-form-model>
    <template slot="footer">
      <a-button style="margin-right: 15px" @click="onClose">取消</a-button>
      <a-button type="primary" :loading="saveLoading" @click="save">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import { listTypeMap } from '../data';

import api from '@/api/xhsAgencyApi';
import _ from 'lodash';

export default {
  components: { TopicTreeInput, BrandSelectInput, CarSeriesSelectInput },
  props: ['defaultType'],
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择选题'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      saveLoading: false,
      noteParam: '',
      noteList: [],
      noteListLoading: false,
      getNoteListFailed: false,
      form: {
        topicSelectionTagIdList: [],
        noteList: [],
        principalData: undefined,
        carSeriesData: undefined,
        type: 'OPERATE',
        title: null,
        template: '',
      },
      listTypeMap,
    };
  },
  computed: {
    principalIds() {
      return this.form.principalData ? [this.form.principalData] : [];
    },
  },
  methods: {
    /**
     * 打开弹窗
     */
    openModal() {
      Object.assign(this.$data, this.$options.data());
      this.form.type = this.defaultType ? this.defaultType : 'OPERATE';
      this.visible = true;
    },
    /**
     * 关闭弹窗
     */
    onClose() {
      this.visible = false;
    },
    /**
     * 品牌变化
     * @param {*} val
     * @return {*}
     */
    handleBrandChange(val) {
      this.form.carSeriesData = undefined
      const arr = val.split(',');
      if (arr.length > 1) {
        this.principalId = arr[0];
        this.principalName = arr[1];
      }
    },
    /**
     * 获取笔记列表
     */
    async getNoteList() {
      try {
        if (!this.noteParam) {
          this.$message.warning('输入小红书笔记ID或笔记链接');
          return;
        }
        this.noteListLoading = true;
        this.getNoteListFailed = false;
        let noteParam = this.noteParam;
        noteParam = noteParam.replace(/\r\n/g, ',');
        noteParam = noteParam.replace(/\n/g, ',');
        noteParam = noteParam.replace(/，/g, ',');
        noteParam = noteParam[noteParam.length - 1] === ',' ? noteParam.slice(0, -1) : noteParam;
        const { code, data, message } = await api.getNoteListByIdOrLink({ noteParam });
        this.noteListLoading = false;
        if (code === 200) {
          this.noteList = data;
        } else {
          this.getNoteListFailed = true;
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.getNoteListFailed = true;
        this.noteListLoading = false;
      }
    },
    /**
     * 保存
     */
    async save() {
      if (this.form.type === 'COPY_WRITING_TEMPLATE') {
        // 文案模板
        if (!this.form.topicSelectionTagIdList.length) return this.$message.info('请至少选择一个选题');
        if (!this.form.template.trim()) return this.$message.info('请输入正文');

        let params = _.cloneDeep(this.form);
        const principalAndCarSeries = {
          carSeriesId: '',
          carSeriesName: '',
          principalId: '',
          principalName: '',
        };

        const principalDataArr = params.principalData ? params.principalData.split(',') : [];
        if (principalDataArr.length > 1) {
          principalAndCarSeries.principalId = principalDataArr[0];
          principalAndCarSeries.principalName = principalDataArr[1];
        }
        delete params.principalData;
        const carSeriesDataArr = params.carSeriesData ? params.carSeriesData.split(',') : [];
        if (carSeriesDataArr.length > 1) {
          principalAndCarSeries.carSeriesId = carSeriesDataArr[0];
          principalAndCarSeries.carSeriesName = carSeriesDataArr[1];
        }

        params.topicTagList = this.form.topicSelectionTagIdList;

        delete params.carSeriesData;
        delete params.topicSelectionTagIdList;
        delete params.noteList;
        delete params.type;

        params = Object.assign(params, principalAndCarSeries);
        try {
          const { code, message } = await api.addCopywritingTemplate(params);
          if (code === 200) {
            this.$message.success('新增成功');
            this.$emit('success');
            this.onClose();
          } else {
            return this.$message.error(`${message}`);
          }
        } catch (error) {
          console.log(error);
        }
        return;
      }

      if (this.form.type === 'PLATFORM' || this.form.type === 'OPERATE') {
        // 平台爆文 运营爆文
        if (this.noteList.length === 0) {
          this.$message.warning('请先获取笔记');
          return;
        }
        const params = _.cloneDeep(this.form);
        params.topicSelectionType = this.form.type;

        const principalAndCarSeries = {
          carSeriesId: undefined,
          carSeriesName: undefined,
          principalId: undefined,
          principalName: undefined,
        };

        const principalDataArr = params.principalData ? params.principalData.split(',') : [];
        if (principalDataArr.length > 1) {
          principalAndCarSeries.principalId = principalDataArr[0];
          principalAndCarSeries.principalName = principalDataArr[1];
        }
        delete params.principalData;
        const carSeriesDataArr = params.carSeriesData ? params.carSeriesData.split(',') : [];
        if (carSeriesDataArr.length > 1) {
          principalAndCarSeries.carSeriesId = carSeriesDataArr[0];
          principalAndCarSeries.carSeriesName = carSeriesDataArr[1];
        }
        delete params.carSeriesData;
        delete params.title;
        delete params.template;
        delete params.type;
        delete params.personalityType;
        params.noteList = this.noteList.map((item) => ({
          id: item.id,
          noteId: item.noteId,
          noteSource: item.noteSource,
          noteTitle: item.noteTitle,
          ...principalAndCarSeries,
        }));

        try {
          const { code, message } = await api.pushNoteToTopic(params);
          if (code === 200) {
            this.$message.success('新增成功');
            this.$emit('success');
            this.onClose();
          } else {
            return this.$message.error(`${message}`);
          }
        } catch (error) {
          console.log(error);
        }
        return;
      }
    },
    /**
     * 选择类型
     */
    handleRadioChange() {
      this.noteParam = null;
      this.noteList = [];
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tips {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-list {
  height: 250px;
  overflow-y: auto;
  .info-column {
    display: flex;
    margin-top: 10px;
    text-align: left;
    .info-left {
      width: 60px;
      height: 60px;
      overflow: hidden;
      background: grey;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info-right {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}
</style>
