<template>
  <a-modal title="文案模板" :maskClosable="false" v-model="visible" @cancel="onClose" centered>
    <a-form-model ref="form" :rules="ruleForm" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
      <a-form-model-item label="选题" required prop="topicTagList">
        <topic-tree-input
          show-search
          style="width: 100%"
          :value="form.topicTagList"
          @change="handelTree"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :replaceFields="{ title: 'name' }"
          placeholder="选题"
          allow-clear
          multiple
          tree-default-expand-all
          :maxTagCount="3"
        >
        </topic-tree-input>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" placeholder="请输入标题"></a-input>
      </a-form-model-item>
      <a-form-model-item label="正文" prop="template">
        <a-textarea
          :auto-size="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入正文"
          v-model="form.template"
        ></a-textarea>
      </a-form-model-item>
      <a-form-model-item label="品牌">
        <brand-select-input
          style="width: 100%"
          placeholder="品牌"
          v-model="form.principalData"
          show-search
          option-filter-prop="children"
          allowClear
          @onChange="handleBrandChange"
        ></brand-select-input>
      </a-form-model-item>
      <a-form-model-item label="车系">
        <car-series-select-input
          style="width: 100%"
          :principalIds="principalIds"
          v-model="form.carSeriesData"
          placeholder="车系"
          show-search
          :filter-option="false"
          allowClear
        ></car-series-select-input>
      </a-form-model-item>
      <a-form-model-item label="账号人设">
        <a-select v-model="form.personalityType" placeholder="人设">
          <a-select-option value="SALE"> 销售 </a-select-option>
          <a-select-option value="STORE"> 门店 </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="Ai改写类型">
        <a-select v-model="form.rewriteType">
          <a-select-option v-for="types in rewriteTypeList" :key="types.aiRewriteType" :value="types.aiRewriteType">
            {{ types.aiRewriteName }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
    </a-form-model>
    <template slot="footer">
      <a-button type="primary" :loading="saveLoading" @click="save">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';
import api from '@/api/xhsAgencyApi';
import _ from 'lodash';

export default {
  components: { TopicTreeInput, BrandSelectInput, CarSeriesSelectInput },
  props: {
    rewriteTypeList: {
      default: () => [],
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请选择选题'));
      } else {
        callback();
      }
    };
    return {
      form: {
        topicTagList: [],
        personalityType: undefined,
        // rewriteType: 'COMMON',
        template: undefined,
        principalData: undefined,
        carSeriesData: undefined,
        title: null,
      },
      ruleForm: {
        topicTagList: [{ validator: validatePass, trigger: 'change' }],
        template: [{ required: true, message: '请输入文案模板', trigger: 'change' }],
      },
      visible: false,
      saveLoading: false,
    };
  },
  computed: {
    principalIds() {
      return this.form.principalData ? [this.form.principalData] : [];
    },
  },
  methods: {
    handelTree(value) {
      this.form.topicTagList = value;
    },
    //获取详情
    async getCopywritingTemplateInfo(id) {
      const { code, data, message } = await api.getCopywritingTemplate({ id: id });
      if (code === 200) {
        this.form = {
          id: data.id,
          topicTagList: data.topicTagList,
          personalityType: data.personalityType || undefined,
          // rewriteType: data.rewriteType,
          title: data.title,
          template: data.template,
          principalData: data.principalId ? `${data.principalId},${data.principalName}` : undefined,
          carSeriesData: data.carSeriesId ? `${data.carSeriesId},${data.carSeriesName}` : undefined,
        };
      } else {
        this.$message.error(message);
      }
    },
    /**
     * 打开弹窗
     */
    openModal(id) {
      if (id) {
        this.getCopywritingTemplateInfo(id);
      }
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
    },
    /**
     * 关闭弹窗
     */
    onClose() {
      this.visible = false;
      this.form = {
        topicTagList: [],
        personalityType: undefined,
        // rewriteType: 'COMMON',
        template: undefined,
        principalData: undefined,
        carSeriesData: undefined,
        title: null,
      };
    },
    /**
     * 品牌变化
     * @param {*} val
     * @return {*}
     */
    handleBrandChange(val) {
      if (val) {
        const arr = val.split(',');
        if (arr.length > 1) {
          this.principalId = arr[0];
          this.principalName = arr[1];
        }
      }
    },

    /**
     * 保存
     */
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = _.cloneDeep(this.form);
          const principalAndCarSeries = {
            carSeriesId: '',
            carSeriesName: '',
            principalId: '',
            principalName: '',
          };

          const principalDataArr = params.principalData ? params.principalData.split(',') : [];
          if (principalDataArr.length > 1) {
            principalAndCarSeries.principalId = principalDataArr[0];
            principalAndCarSeries.principalName = principalDataArr[1];
          }
          delete params.principalData;
          const carSeriesDataArr = params.carSeriesData ? params.carSeriesData.split(',') : [];
          if (carSeriesDataArr.length > 1) {
            principalAndCarSeries.carSeriesId = carSeriesDataArr[0];
            principalAndCarSeries.carSeriesName = carSeriesDataArr[1];
          }
          delete params.carSeriesData;
          params = Object.assign(params, principalAndCarSeries);
          try {
            const { code, message } = !params.id
              ? await api.addCopywritingTemplate(params)
              : await api.updateCopywritingTemplate(params);
            if (code === 200) {
              params.id ? this.$message.success('编辑成功') : this.$message.success('新增成功');
              this.form = {
                topicTagList: [],
                personalityType: undefined,
                // rewriteType: 'COMMON',
                template: undefined,
                principalData: undefined,
                carSeriesData: undefined,
                title: null,
              };
              this.$emit('success');
              this.onClose();
            } else {
              return this.$message.error(`${message}`);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.$message.error('校验不通过');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tips {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-list {
  height: 250px;
  overflow-y: auto;
  .info-column {
    display: flex;
    margin-top: 10px;
    text-align: left;
    .info-left {
      width: 60px;
      height: 60px;
      overflow: hidden;
      background: grey;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info-right {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}
</style>
